const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        api: "https://hoabinh-api.cgis.asia/",
        media_url: "https://hoabinh-api.cgis.asia/",
        domainAdminSide: "http://localhost:3000",
        domainUserSide: "http://localhost:3001",
        domainName: "",
        workSpace: "hoabinh",
        //wmsBaseLink: "https://geo.cgis.asia/geoserver/hoabinh/wms/",
        wmsBaseLink: "https://geo.cgis.asia/geoserver/gwc/service/wms/",
  },
  production: {
	  api: "https://qhhohoabinh-api.hoabinh.gov.vn/",
      media_url: "https://qhhohoabinh-api.hoabinh.gov.vn/",
      domainAdminSide: "https://qhhohoabinh-admin.hoabinh.gov.vn",
      domainUserSide: "https://qhhohoabinh.hoabinh.gov.vn",
      domainName: "hoabinh.gov.vn",
      workSpace: "hohoabinh",
      wmsBaseLink: "https://geo.hoabinh.gov.vn/geoserver/hohoabinh/wms/",
  },
};

module.exports = apiEnvironment[env];
